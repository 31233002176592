<template>
  <div class="LoginFormByPhone">
    <div class="tip1">账号密码登录</div>
    <div style="text-align: left">
      <el-form
          ref="formEl"
          :model="form"
          status-icon
          :rules="rules"
          label-width="0"
      >
        <el-form-item label="" prop="phone">
          <el-input
              v-model.number="form.phone"
              autofocus
              clearable
          ></el-input>

        </el-form-item>

        <el-form-item label="" prop="password">
          <el-input
              v-model="form.password"
              show-password
              type="password"
              clearable
          ></el-input>

        </el-form-item>

        <el-form-item>
          <el-button ref="btnEl" type="primary"
                     style="color: #FFF; background: #FF9900; border-color: #FF9900; width: 100%" @click="onLogin"
                     :loading="loading">登录
          </el-button>
        </el-form-item>


      </el-form>
    </div>
  </div>
</template>

<script>
import {nextTick, onMounted, reactive, readonly, ref, toRefs} from "vue";
import {useCssVar} from "@vueuse/core";
import {useRoute, useRouter} from "vue-router";
import {ElMessage} from 'element-plus'
import {JSEncrypt} from "jsencrypt";
import service from "../../utils/axios";

export default {
  name: "LoginFormByPhone",
  data() {
    return {}
  },
  setup() {
    let state = reactive({
      form: {
        phone: null,
        password: null,
      },
      loading: false,
    })

    let readonlyState = readonly({
      rules: {
        phone: [{required: true, message: '请输入用户名', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
      }
    })

    const getLogin = async () => {
      state.loading = true;
      const data = await service.post('/dwart/user/user/userLogin/dp', {
        mobile: state.form.phone,
        cipherText: passwordEncrypt(state.form.password)
      })
      state.loading = false
      if (data.result) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('login-data', JSON.stringify(data));
        router.push({path: '/map',})

      } else {
        ElMessage.error(data.message)
      }
    }


    const passwordEncrypt = (password) => {
      let encrypt = new JSEncrypt();
      encrypt.setPublicKey(
          `-----BEGIN PUBLIC KEY-----
      MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCoZhB+bWaBwuuGTtxpMs2xqoyiFRCB5Ou0pvUtZeePAMDra0UNMqumfyrXPL/bmO8hh9q87+Bg7R0d1qFZiniK7W3SPkTaNyqWKGkUZuCyVUu6gK/5+K3RItRUC+he6yp7kDvZv3j73ZEmWQVqNX0UQahk69bxbt7K11q1SYWrzQIDAQAB
    -----END PUBLIC KEY-----`
      )
      return encrypt.encrypt(password);
    }

    const onLogin = () => {
      formEl.value.validate(v => {
        if (v) {
          getLogin()
        }
      })
    }

    const route = useRoute();
    const router = useRouter();
    const formEl = ref(null)
    const btnEl = ref(null)
    const formPrimaryColor = useCssVar('--el-color-primary', formEl);

    onMounted(() => {
      nextTick(() => {
        formPrimaryColor.value = '#FF9900'
      })
      if (route.query.flag && route.query.flag === 'client') {
        state.form.phone = route?.query.name
        state.form.password = route?.query.p
        getLogin()
      }
    })

    return {
      ...toRefs(state),
      ...readonlyState,
      formEl,
      btnEl,
      onLogin
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
.LoginFormByPhone {
  width: 360px;
  display: inline-block;
  margin-top: 30px;

  .tip1 {
    color: #ff9000;
    font-weight: 700;
    font-size: 17px;
    padding: 20px;
    border-bottom: 2px solid #FF9900;
    cursor: default;
    display: inline-block;
    margin-bottom: 50px;
  }
}
</style>
