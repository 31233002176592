<template>
  <div class="LoginFormByScan" >
    <div id="login_container"></div>
  </div>
</template>

<script>
import {onMounted} from "vue";

export default {
  name: "LoginFormByScan",
  data() {
    return {}
  },
  setup(){

    const getDDcode = () => {
      const appid = "dingoakedcccoqybw4suun";
      let url = encodeURIComponent("http://data.dawei.art/#/getcode");
      let goto = encodeURIComponent(
          `https://oapi.dingtalk.com/connect/qrconnect?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}`
      );
      let obj = DDLogin({
        id: "login_container",
        goto: goto,
        style: "border:none;background-color:#FFFFFF;",
        width: "300",
        height: "300"
      });
    }

    const handleMessage = (event) => {
      let origin = event.origin;
      if (origin == "https://login.dingtalk.com") {
        //判断是否来自ddLogin扫码事件。
        let loginTmpCode = event.data; //拿到loginTmpCode后就可以在这里构造跳转链接进行跳转了
        const redirect_uri = "http://www.dawei.art";
        const appid = "dingoas1dcifod1zriwh5b";

        window.location.href =
            `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${redirect_uri}&loginTmpCode=` +
            loginTmpCode;
      }
    }

    onMounted(()=>{
      getDDcode();
      if (typeof window.addEventListener !== 'undefined') {
        window.addEventListener('message', handleMessage, false);
      } else if (window.attachEvent !== 'undefined') {
        window.attachEvent("onmessage", handleMessage);
      }
    })


    return  {

    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
.LoginFormByScan {
  margin-top: 60px;
  @media (max-width: 1280px){
    margin-top: 40px;
  }
}
</style>
